// Copyright 2024 Qatalyst Health. All Rights Reserved.
// Main landing page for website.

import React, { useState } from 'react';
import logo from '../assets/qatalyst-primary-logo.png'
import '../styles/components/navHeader.css'

import { Navbar, Nav, Container, NavbarBrand } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function NavHeader() {

  const navigate = useNavigate();

  return (
      <Navbar collapseOnSelect bg="white" expand="md" fixed="top" className="d-flex header-container">
        <Container>
          <NavbarBrand href="/">
            <img src={logo} className="d-inline-block qh-logo" alt="Qatalyst Health logo"/>
          </NavbarBrand>

          <Navbar.Toggle aria-controls="basic-navbar-nav"/>

          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="nav-box-container">
              <Nav className="nav-box">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/coverage">Coverage</Nav.Link>
                  <Nav.Link href="/team">Team</Nav.Link>
                  <Nav.Link href="/contact">Contact</Nav.Link>
              </Nav>
            </div>

            <Nav className='customer-service-btn ms-auto'
                 onClick={() => navigate('/customer-service')}>
                  <Nav.Link href="/customer-service" className="customer-service-link">Customer Service</Nav.Link>
            </Nav>
          </Navbar.Collapse>

          </Container>
      </Navbar>
  )
}

export default NavHeader

