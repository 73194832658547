// Copyright 2024 Qatalyst Health. All Rights Reserved.
// Biography page for team members.

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MdArrowBackIos } from "react-icons/md";

import teamMembers from '../data/teamMembers';
import '../styles/pages/teamMemberPage.css'
import linkedinIcon from '../assets/linkedin-blue.png';

function TeamMemberPage() {
    const { memberId } = useParams();
    const navigate = useNavigate();
    const member = teamMembers[memberId];

    if (!member) {
        return (
            <div>
                <h1>Member not found</h1>
            </div>
        )
    }

    const { name, subheading, imageUrl, bioText, roleText, contactLink } = member;

    return(
        <div className="content team-member">
            <Container>
                <Row>
                    <Col xs={12} className='back-btn-container'>
                        <button className='back-btn' onClick={() => navigate('/team')}>
                        <MdArrowBackIos /> Back</button>
                    </Col>
                </Row>

                <Row className='team-member-container'>
                    <Col xs={12} md={6} className='image-section'>
                        <div className='page-title sm'>
                            <a href={contactLink}><img src={linkedinIcon} className='linkedin-icon sm' alt="linkedin"/></a> {name}
                        </div>
                        <span className='subheading sm'>{subheading}</span>

                        <div className='headshot'>
                            <img src={imageUrl} alt={name}></img>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className='bio-section'>
                        <span className='page-title md'>
                            <a href={contactLink}><img src={linkedinIcon} className='linkedin-icon md' alt="linkedin"/></a> {name}
                        </span>
                        <span className='subheading md'>{subheading}</span>
                        <br/>

                        <span className='bio'>{bioText}</span>
                        <br />
                        <br />
                        <span className='bio'>{roleText}</span>
                    </ Col>
                </Row>
            </Container>
        </div>
    )
}

export default TeamMemberPage;