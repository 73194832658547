import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.css';
import NavHeader from './components/NavHeader';
import HomePage from './pages/HomePage';
import CoveragePage from './pages/CoveragePage';
import TeamPage from './pages/TeamPage';
import TeamMemberPage from './pages/TeamMemberPage';  
import ContactPage from './pages/ContactPage';
import CustomerServicePage from './pages/CustomerServicePage';


const App = () => (
  <Router>
    <NavHeader />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/coverage" element={<CoveragePage />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/customer-service" element={<CustomerServicePage />} />
      <Route path="/team/:memberId" element={<TeamMemberPage />} />
    </Routes>
  </Router>
);

export default App;
