// Copyright 2024 Qatalyst Health. All Rights Reserved.
// Meet the Team page for website.

import React from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../styles/pages/teamPage.css'
import nye_headshot from '../assets/team/Andrew_Nye_Headshot.png'
import mendez_headshot from '../assets/team/Brandon_Mendez_Headshot.png'
import savage_headshot from '../assets/team/Jeff_Savage_Headshot.jpg'
import myers_headshot from '../assets/team/Jason_Myers_Headshot.jpg'

function TeamPage() {
    const navigate = useNavigate();

    return (
        <div className="content team-page">
            <Container>
                <Row>
                    <Col xs={12} className="team-header">
                        <span className='title team-page'>Meet the team</span>

                        <span className='description'>
                            Our team includes a collection of experts with a knowledge base crossing
                            multiple disciplines. We combine our skill sets to accomplish our sole
                            mission of enhancing the care you give to patients.</span>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6} lg={3} className="team-card">
                        <div className="card">
                            <img src={nye_headshot} alt="Andrew Nye" />
                            <div className='card-content'>
                                <span className="card-subtitle">CEO</span>
                                <span className="card-title">Andrew Nye</span>
                                <button className="card-btn"
                                        onClick={() => navigate('/team/andrew')}>
                                    <span>Learn more</span>
                                </button>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={6} lg={3} className="team-card">
                        <div className="card">
                            <img src={mendez_headshot} alt="Brandon Mendez" />
                            <div className='card-content'>
                                <span className="card-subtitle">VP of Operations</span>
                                <span className="card-title">Brandon Mendez</span>
                                <button className="card-btn"
                                        onClick={() => navigate('/team/brandon')}>
                                    <span>Learn more</span>
                                </button>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={6} lg={3} className="team-card">
                        <div className="card">
                            <img src={savage_headshot} alt="Jeff Savage" />
                            <div className='card-content'>
                                <span className="card-subtitle">VP of Sales</span>
                                <span className="card-title">Jeff Savage</span>
                                <button className="card-btn"
                                        onClick={() => navigate('/team/jeff')}>
                                    <span>Learn more</span>
                                </button>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={6} lg={3} className="team-card">
                        <div className="card">
                            <img src={myers_headshot} alt="Jason Myers" />
                            <div className='card-content'>
                                <span className="card-subtitle">VP of Development</span>
                                <span className="card-title">Jason Myers</span>
                                <button className="card-btn"
                                        onClick={() => navigate('/team/jason')}>
                                    <span>Learn more</span>
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default TeamPage;