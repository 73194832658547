// Copyright 2024 Qatalyst Health. All rights reserved.
// Contact page for website.

import React, { useRef, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../styles/pages/contactPage.css';
import background from '../assets/wave-haikei.svg';

function ContactPage() {
    const form = useRef();
    const [messageSent, setMessageSent] = useState(false);

    useEffect(() => {
        document.body.style.height = '100vh'
        document.body.style.backgroundImage = `url(${background})`;
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center bottom";
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundSize = "cover";

        return () => {
            document.body.style.backgroundImage = '';
            document.body.style.height = '';
        };
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_w9hdb6b', 'template_qbv3e79', form.current, '7SUJ0jArFf2v2yIZ0')
        .then(
            (result) => {
                console.log('SUCCESS!', result.text);
                setMessageSent(true);
            },
            (error) => {
                console.log('FAILED...', error.text);
                alert('Failed to send your message. Please try again.');
            }
        );

        e.target.reset();   // Reset the form after submission
    };

    return(
        <div className="content contact-page">
            <Container>
                <Row xs={1} md={2}>
                    <Col className='contact-text-container'>
                        <span className="title contact">Got a question?</span><br/>
                        <span className="info-text">Please leave us a message and we will get back to you as soon as we can!</span>
                    </Col>

                    <Col className='contact-form-container'>
                        <div className="form-box">
                            <form ref={form} id="contactForm" className="form contact-form" onSubmit={sendEmail}>
                                <div className="form-group">
                                    <input type="text" id="firstName" name="firstName" placeholder="First Name" required />
                                    <input type="text" id="lastName" name="lastName" placeholder="Last Name" required />
                                </div>
                                <div className="form-group form-row">
                                    <input type="email" id="email" name="email" placeholder="Email" required />
                                </div>
                                <div className="form-group form-row">
                                    <input type="tel" id="phone" name="phone" placeholder="Phone Number" required />
                                </div>
                                <div className="form-group-msg">
                                    <textarea id="message" name="message" placeholder="Your Message..." required></textarea>
                                </div>
                                <button type="submit" className="contact-form-btn">Submit</button>
                            </form>
                        </div>

                        <div className='success-message contact' style={{display: messageSent ? 'block' : 'none'}}>
                            Your message has been sent successfully. We'll be in touch soon!
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default ContactPage;