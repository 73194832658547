// Copyright 2024 Qatalyst Health. All Rights Reserved.
// Team member data used to populate TeamPage and TeamMemberPage.

import andrewImg from '../assets/team/Andrew_Nye_Headshot.png'
import brandonImg from '../assets/team/Brandon_Mendez_Headshot.png'
import jeffImg from '../assets/team/Jeff_Savage_Headshot.jpg'
import jasonImg from '../assets/team/Jason_Myers_Headshot.jpg'

const teamMembers = {
    andrew: {
        name: 'Andrew Nye',
        subheading: 'Chief Executive Officer',
        imageUrl: andrewImg,
        bioText: 'Andrew’s entrepreneurial journey started young, co-founding a volatility capture hedge fund as a sophomore in college. While concurrently serving as the CFO of the hedge fund, he acted as an outside financial consultant to various companies where he built complex financial models and advised on nearly 500M in transactional value. On one of his various consulting engagements, he stumbled across the complexities of healthcare reimbursement in long term care facilities and decided to build the next age of automation software for the industry.',
        roleText: 'As the CEO, he is responsible for all functions and the daily operations of Qatalyst Health. Additionally, he single-handedly developed the initial automation software and continues to lead the software team.',
        contactLink: 'https://www.linkedin.com/in/andrew-nye2002/'
    },

    brandon: {
        name: 'Brandon Mendez, PhD, CPA',
        subheading: 'VP of Operations',
        imageUrl: brandonImg,
        bioText: 'Dr. Brandon Mendez is a distinguished finance professional and academic with a wealth of experience spanning over fourteen years. He currently serves as a Clinical Assistant Professor of Finance at the Darla Moore School of Business where he teaches courses in corporate finance and investments. Brandon\'s academic journey is marked by a Ph.D. in finance from Florida State University, an MBA from the University of North Florida, and a Bachelor of Science in Business Administration from the College of Charleston. Brandon\'s professional experience encompasses roles in financial services, internal auditing, regulatory compliance, and management consulting. He has served as an auditor-in-charge at EverBank/TIAA Bank, where he focused on regulatory compliance, risk management, and internal controls. Additionally, his consulting career includes notable projects such as Medicaid cost auditing for the State of New York.',
        roleText: 'A veteran of the U.S. Navy, Brandon served as a Naval Flight Officer. His military background has been instrumental in his ability to manage large-scale projects and lead diverse teams effectively.',
        contactLink: 'https://www.linkedin.com/in/brandon-mendez-664abb43/'
    },

    jeff: {
        name: 'Jeff Savage, PhD',
        subheading: 'VP of Sales',
        imageUrl: jeffImg,
        bioText: 'Jeff is a clinical professor of Strategy and Entrepreneurship, and a director of the Faber Entrepreneurship Center, at the Moore School of Business. He earned his Ph.D. in Strategy and Entrepreneurship from the University of Illinois Urbana-Champaign. Focusing his research on strategic growth and innovation, Jeff has advised businesses ranging from Fortune 500 companies to cities, governments, and multiple National Laboratories. He truly enjoys helping things grow, from startups he has co-founded or mentored to raising his 4 children and many pets children (3 dogs, 3 cats, a snake and 5 chickens at last count currently).',
        roleText: 'As the VP of Sales, he is responsible for all functions and the daily operations of the sales team at Qatalyst Health. He plays a key role in developing and executing sales strategies that align with organizational goals.',
        contactLink: 'https://www.linkedin.com/in/jeff-savage-phd/'
    },

    jason: {
        name: 'Jason Myers',
        subheading: 'VP of Development',
        imageUrl: jasonImg,
        bioText: 'Jason is an industry expert with 11+ years of global real estate experience in the healthcare space. He is the former Head of UK for Welltower™ (NYSE:WELL), the world’s largest healthcare REIT valued at $50B. He has overseen $3B+ in acquisition and development value and worked with leading operators in the senior housing industry across the care continuum.',
        roleText: 'As the VP of Development, he supports the executive team on their endeavors and provides strategic guidance on the company\'s growth and development.',
        contactLink: 'https://www.linkedin.com/in/jason-david-myers/'
    }
};

export default teamMembers;
