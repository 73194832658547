// Copyright 2024 Qatalyst Health. All Rights Reserved.

// Index file for setting up website.


import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './styles/app.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App'

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

reportWebVitals(console.log);
