// Copyright 2024 Qatalyst Health. All Rights Reserved.
// Page displaying coverage map for website.

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { geoAlbersUsa } from 'd3-geo';
import us from 'us-atlas/states-10m.json';

import '../styles/pages/coveragePage.css';

const stateData = {
  "California": "MEDICARE",
  "Texas": "MEDICARE",
  "New York": "MEDICARE",
  "Florida": "BOTH",
  "South Carolina": "BOTH",
  "Georgia": "BOTH",
  "Illinois": "MEDICARE",
  "Ohio": "MEDICARE",
  "Pennsylvania": "MEDICARE",
  "Michigan": "MEDICARE",
  "North Carolina": "BOTH",
  "Indiana": "MEDICARE",
  "Arizona": "MEDICARE",
  "Tennessee": "MEDICARE",
  "New Jersey": "MEDICARE",
  "Missouri": "MEDICARE",
  "Maryland": "MEDICARE",
  "Wisconsin": "MEDICARE",
  "Minnesota": "MEDICARE",
  "Colorado": "MEDICARE",
  "Alabama": "MEDICARE",
  "Louisiana": "MEDICARE",
  "Kentucky": "MEDICARE",
  "Oregon": "MEDICARE",
  "Oklahoma": "MEDICARE",
  "Connecticut": "MEDICARE",
  "Iowa": "MEDICARE",
  "Mississippi": "MEDICARE",
  "Arkansas": "MEDICARE",
  "Utah": "MEDICARE",
  "Nevada": "MEDICARE",
  "Kansas": "MEDICARE",
  "New Mexico": "MEDICARE",
  "Nebraska": "MEDICARE",
  "West Virginia": "MEDICARE",
  "Idaho": "MEDICARE",
  "Hawaii": "MEDICARE",
  "Maine": "MEDICARE",
  "New Hampshire": "MEDICARE",
  "Montana": "MEDICARE",
  "Rhode Island": "MEDICARE",
  "Delaware": "MEDICARE",
  "South Dakota": "MEDICARE",
  "North Dakota": "MEDICARE",
  "Vermont": "MEDICARE",
  "Alaska": "MEDICARE",
  "Wyoming": "MEDICARE"
};

const fills = {
  MEDICARE: "#af9857",
  BOTH: "#37454f",
  defaultFill: "#af9857"
};

const projection = geoAlbersUsa();

function CoveragePage () {
  return (
    <div className="content coverage-page">
      <Container className='coverage-container'>
        <div className='coverage-header'>
          <p><span className='title coverage-page'>Meet ROSA</span></p>
          <p><span className='subheading coverage-page'>
            By integrating into your EHR, ROSA (Reimbursement Optimization Software Application) will automatically fill out and update your reimbursement forms for you. See Rosa's payer mix coverage below, and click the Learn More button to see our marketplace listing on PointClickCare's website.
            </span></p>
          <a href='https://marketplace.pointclickcare.com/s/partner-app/aFC5G000000fyv7WAA/rosa-by-qatalyst-health'>
            <button className='learn-btn coverage-page'>Learn More About ROSA</button>
          </a>
        </div>

        <center>
        <div className='map-container'>
          <ComposableMap projection={projection} width={900} height={550}>
            <Geographies geography={us}>
              {({ geographies }) =>
                geographies.map(geo => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={fills[stateData[geo.properties.name] || "defaultFill"]}
                    stroke="#ffffff"
                    style={{
                      default: { outline: "none" },
                      hover: { outline: "none" },
                      pressed: { outline: "none"}
                    }}
                  />
                ))
              }
            </Geographies>
          </ComposableMap>

          <div className="legend">
            <div className="legend-item">
              <div className="legend-color medicare"></div>
              <span>Medicare</span>
            </div>

            <div className="legend-item both">
              <div className="legend-color both"></div>
              <span>Medicare and Medicaid</span>
            </div>
          </div>
        </div>
        </center>
      </Container>
    </div>
)}

export default CoveragePage;